import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BLACK, GRAY_01, GRAY_03, SKYBLUE, WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, OUTER_URLS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { vlabData } from '../../data/vlab';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import MobileLineLinkButton from '../common/MobileLineLinkButton';
import Outlink from '../common/Outlink';
import SectionTitleSet from '../common/SectionTitleSet';

const VLabSection = () => {
	const [selectedItem, setSelectedItem] = useState(vlabData[0]);
	const { text, image } = selectedItem;
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.Black,
	});

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VLAB} />
			<Wrapper ref={sectionRef}>
				<ContentWrapper>
					<SectionTitleSet
						title="V+Lab"
						description={
							<>
								<span css={showOnDesktopStyle}>
									우리 아이의 건강과 기호성에 집중한 맞춤형 헬스 케어 브랜드로써
									<br />
									푸드부터 용품까지 건강한 반려 라이프를 선도하고 있습니다.
								</span>
								<span css={showOnMobileStyle}>
									우리 아이의 건강과 기호성에 집중한
									<br />
									맞춤형 헬스 케어 브랜드
								</span>
							</>
						}
						textAlign="LEFT"
					/>
					<Outlink
						text="쇼핑몰 바로가기"
						href={OUTER_URLS.VLAB}
						color={SKYBLUE}
						styles={css`
							margin-top: ${pxToRem(32)};
						`}
					/>
					<MobileButtonWrapper>
						<MobileLineLinkButton link={OUTER_URLS.VLAB} type="BLACK">
							쇼핑몰 바로가기
						</MobileLineLinkButton>
					</MobileButtonWrapper>
					<ImageWrapperDesktop>
						<MainImageWrapper>
							<MainImage src={image} alt="" />
							<Background />
							<MainText>
								<span>{text.description}</span>
								<MainTitle>{text.title}</MainTitle>
							</MainText>
						</MainImageWrapper>
						<ThumbnailList>
							{vlabData.map((data) => (
								<ThumbnailItem key={data.id}>
									<ThumbnailButton
										type="button"
										onClick={() => setSelectedItem(data)}
										aria-label="제품 이미지 크게 보기"
									>
										<Thumbnail src={data.thumbImage} alt="" />
									</ThumbnailButton>
								</ThumbnailItem>
							))}
						</ThumbnailList>
					</ImageWrapperDesktop>
					<ImgWrapperMobile>
						<Swiper
							modules={[Pagination, A11y, Autoplay]}
							loop={true}
							pagination={{
								clickable: true,
							}}
							autoplay={{
								delay: 3000,
								disableOnInteraction: false,
							}}
							spaceBetween={15}
						>
							{vlabData.map((data) => (
								<SwiperSlide key={data.id}>
									<MainImageWrapper>
										<MainImage src={data.image} alt="" />
										<Background />
										<MainText>
											<span>{data.text.description}</span>
											<MainTitle>{data.text.title}</MainTitle>
										</MainText>
									</MainImageWrapper>
								</SwiperSlide>
							))}
						</Swiper>
					</ImgWrapperMobile>
				</ContentWrapper>
			</Wrapper>
		</>
	);
};

export default VLabSection;

const MobileButtonWrapper = styled.div`
	margin-top: ${pxToRem(28)};
	text-align: center;
`;

const Thumbnail = styled.img`
	display: block;
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

const ThumbnailButton = styled.button`
	width: 100%;
	height: 100%;
`;

const ThumbnailItem = styled.li`
	box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);
`;

const ThumbnailList = styled.ul`
	display: grid;
	grid: repeat(2, 223px) / repeat(2, 1fr);
	grid-row-gap: ${pxToRem(16)};
	grid-column-gap: ${pxToRem(20)};
`;

const MainTitle = styled.span`
	font-size: ${pxToRem(14)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(22)};
	`)}
`;

const MainText = styled.p`
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: ${WHITE};
	height: ${pxToRem(54)};
	font-size: ${pxToRem(11)};

	${getDesktopStyle(css`
		bottom: 0;
		height: ${pxToRem(81)};
		font-size: ${pxToRem(17)};
	`)}
`;

const Background = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: ${BLACK};
	mix-blend-mode: multiply;
	height: ${pxToRem(54)};

	${getDesktopStyle(css`
		bottom: 0;
		height: ${pxToRem(81)};
	`)}
`;

const MainImage = styled.img`
	display: block;
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

const MainImageWrapper = styled.div`
	position: relative;

	${getDesktopStyle(css`
		flex: 0 0 ${pxToRem(556)};
		margin-right: ${pxToRem(30)};
		box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);
	`)}
`;

const ImageWrapperDesktop = styled.div`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		display: flex;
		height: ${pxToRem(462)};
		margin-top: ${pxToRem(128)};
	`)}
`;

const ImgWrapperMobile = styled.div`
	${showOnMobileStyle};

	margin-top: ${pxToRem(40)};

	.swiper {
		height: calc(72.5vw + 50px);
	}
	${swiperPagenationStyle({ bgColor: GRAY_03, activeColor: BLACK })};
`;

const ContentWrapper = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const Wrapper = styled.section`
	background-color: ${WHITE};
	padding: ${pxToRem(80)} ${pxToRem(24)};
	color: ${BLACK};

	${getDesktopStyle(css`
		padding: ${pxToRem(112)} 0;
		background-color: ${GRAY_01};
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;
